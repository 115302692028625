body {
  margin: 0;
  color: #a8aeba;
  background-color: #fff;
  font-size: 1rem;
  font-family: 'Noto Sans JP', sans-serif;
  font-feature-settings: 'palt' 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-kerning: auto;
}
body.en {
  font-family: 'Noto Sans JP', sans-serif;
}
body.en strong,
body.en b,
body.en .bold,
body.en .font-weight-bold {
  font-family: 'Noto Sans JP', sans-serif;
}
body.ja {
  font-family: 'Noto Sans JP', sans-serif;
}
body.ja strong,
body.ja b,
body.ja .bold,
body.ja .font-weight-bold {
  font-family: 'Noto Sans JP', sans-serif;
}
body.zh {
  font-family: 'Noto Sans JP', sans-serif;
}
body.zh strong,
body.zh b,
body.zh .bold,
body.zh .font-weight-bold {
  font-family: 'Noto Sans JP', sans-serif;
}
p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}
.application {
  width: 100%;
  min-height: 100%;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.sortby-dropdown .ant-dropdown-menu-item {
  padding: 0;
  cursor: not-allowed;
}
.sortby-dropdown .ant-dropdown-menu-item .menu-item {
  padding: 5px 12px;
  cursor: pointer;
}
.sortby-dropdown .ant-dropdown-menu-item .menu-item.active {
  user-select: none;
  pointer-events: none;
  opacity: 0.2;
  cursor: not-allowed;
}
.modal-galley-item {
  width: 100% !important;
  height: 100%;
  transform-origin: unset !important;
  padding: 0;
  display: flex !important;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  position: fixed;
  z-index: 999999;
  background-color: rgba(18, 19, 26, 0.7);
}
.modal-galley-item .ant-modal-content {
  background: transparent;
  border-radius: 0 !important;
  box-shadow: none;
}
.modal-galley-item .ant-modal-content .ant-modal-close {
  right: 20px;
  background: transparent;
}
.modal-galley-item .ant-modal-content .ant-modal-close .ant-modal-close-x {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
}
.modal-galley-item .ant-modal-content .ant-modal-body {
  background: transparent;
}
.modal-galley-item .ant-modal-content .ant-modal-body .swiper-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-galley-item .ant-modal-content .ant-modal-body .swiper-container .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  background-color: #fff100;
}
.modal-galley-item .ant-modal-content .ant-modal-body .swiper-container .swiper-button-next,
.modal-galley-item .ant-modal-content .ant-modal-body .swiper-container .swiper-button-prev {
  color: #fff100;
}
.modal-galley-item .ant-modal-content .ant-modal-body .swiper-container .swiper-pagination-bullet-active {
  background-color: #fff100;
  color: #fff100;
}
.modal-galley-item .ant-modal-content .ant-modal-body .swiper-container .swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
  align-items: center;
}
.modal-galley-item .ant-modal-content .ant-modal-body .swiper-container .swiper-wrapper .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-galley-item .ant-modal-content .ant-modal-body .swiper-container .swiper-wrapper .swiper-slide .thumbnail-image {
  max-height: 80vh;
  max-width: 70vw;
  min-height: 70vh;
}
@media screen and (max-width: 768px) {
  .modal-galley-item .ant-modal-content .ant-modal-close .ant-modal-close-x {
    font-size: 20px;
  }
  .modal-galley-item .ant-modal-content .ant-modal-body .swiper-container .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    background-color: #fff100;
  }
  .modal-galley-item .ant-modal-content .ant-modal-body .swiper-container .swiper-button-next,
  .modal-galley-item .ant-modal-content .ant-modal-body .swiper-container .swiper-button-prev {
    color: #fff100;
  }
  .modal-galley-item .ant-modal-content .ant-modal-body .swiper-container .swiper-button-next::after,
  .modal-galley-item .ant-modal-content .ant-modal-body .swiper-container .swiper-button-prev::after {
    font-size: 25px;
  }
  .modal-galley-item .ant-modal-content .ant-modal-body .swiper-container .swiper-pagination-bullet-active {
    background-color: #fff100;
    color: #fff100;
  }
}
/*
:root {
  --swiper-pagination-color: var(--swiper-theme-color);
  --swiper-pagination-bullet-size: 8px;
  --swiper-pagination-bullet-width: 8px;
  --swiper-pagination-bullet-height: 8px;
  --swiper-pagination-bullet-inactive-color: #000;
  --swiper-pagination-bullet-inactive-opacity: 0.2;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 4px;
  --swiper-pagination-bullet-vertical-gap: 6px;
}
*/
.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
}
.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}
/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 10px;
  left: 0;
  width: 100%;
}
/* Bullets */
.swiper-pagination-bullets-dynamic {
  overflow: hidden;
  font-size: 0;
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transform: scale(0.33);
  position: relative;
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active {
  transform: scale(1);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
  transform: scale(1);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
  transform: scale(0.66);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
  transform: scale(0.33);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
  transform: scale(0.66);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
  transform: scale(0.33);
}
.swiper-pagination-bullet {
  width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 8px));
  height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 8px));
  display: inline-block;
  border-radius: 50%;
  background: var(--swiper-pagination-bullet-inactive-color, #000);
  opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.2);
}
button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  appearance: none;
}
.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}
.swiper-pagination-bullet:only-child {
  display: none !important;
}
.swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: var(--swiper-pagination-color, var(--swiper-theme-color));
}
.swiper-vertical > .swiper-pagination-bullets,
.swiper-pagination-vertical.swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  transform: translate3d(0px, -50%, 0);
}
.swiper-vertical > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: var(--swiper-pagination-bullet-vertical-gap, 6px) 0;
  display: block;
}
.swiper-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
.swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
}
.swiper-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet,
.swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  display: inline-block;
  transition: 200ms transform, 200ms top;
}
.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
}
.swiper-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}
.swiper-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transition: 200ms transform, 200ms left;
}
.swiper-horizontal.swiper-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transition: 200ms transform, 200ms right;
}
/* Progress */
.swiper-pagination-progressbar {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
}
.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: var(--swiper-pagination-color, var(--swiper-theme-color));
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: scale(0);
  transform-origin: left top;
}
.swiper-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  transform-origin: right top;
}
.swiper-horizontal > .swiper-pagination-progressbar,
.swiper-pagination-progressbar.swiper-pagination-horizontal,
.swiper-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,
.swiper-pagination-progressbar.swiper-pagination-vertical.swiper-pagination-progressbar-opposite {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0;
}
.swiper-vertical > .swiper-pagination-progressbar,
.swiper-pagination-progressbar.swiper-pagination-vertical,
.swiper-horizontal > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,
.swiper-pagination-progressbar.swiper-pagination-horizontal.swiper-pagination-progressbar-opposite {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}
.swiper-pagination-lock {
  display: none;
}
.sp {
  display: none !important;
}
@media screen and (max-width: 768px) {
  .pc {
    display: none !important;
  }
  .sp {
    display: block !important;
  }
}
#web-body {
  padding-top: 0;
}
.transfer-icon {
  filter: brightness(0) invert(1);
}
.ant-dropdown-menu {
  box-shadow: 0px 0px 10px rgba(232, 189, 219, 0.3) !important;
  background-color: #fff !important;
  border-radius: 10px 10px 10px 10px !important;
}
.ant-dropdown-menu .ant-dropdown-menu-item {
  background-color: transparent !important;
}
.ant-dropdown-menu .ant-dropdown-menu-item:hover {
  opacity: 0.5;
}
.ant-dropdown-menu .ant-dropdown-menu-title-content {
  color: #fff !important;
}
.ant-dropdown-menu .ant-menu-vertical {
  border-right: none;
}
.ant-dropdown-menu.menu-category li {
  color: #fff !important;
}
::selection {
  background: #3cc8fc !important;
}
.ant-select-item:hover {
  background-color: #d6d6d6;
}
.ant-select-item-option-content {
  color: #282828;
}
.ant-select-item-option-content:hover {
  opacity: 0.5;
}
.ant-popover {
  border-radius: 8px !important;
}
.ant-popover-arrow-content {
  margin: 0;
  box-shadow: 0px 0px 10px rgba(232, 189, 219, 0.3) !important;
}
.ant-popover-arrow-content::before {
  background-color: #fff !important;
}
.ant-popover-content {
  box-shadow: 0px 0px 10px rgba(232, 189, 219, 0.3) !important;
  background-color: #1b1e24;
}
.ant-popover-content .ant-popover-inner-content {
  padding: 4px;
  background-color: #fff;
}
.ant-popover-content .ant-popover-inner {
  background-color: transparent;
}
.ant-dropdown {
  background-color: #fff !important;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.ant-select-selector {
  border: none;
  box-shadow: none;
  background-color: #fff !important;
}
.ant-select-selector .ant-select-selection-item {
  color: #a8aeba;
}
.ant-select-selector .ant-select-selection-placeholder {
  color: #a8aeba;
}
.ant-select-selector .ant-select-arrow {
  color: #fff;
}
.ant-select-dropdown {
  padding: 20px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25) !important;
  border-radius: 20px 0 20px 20px !important;
}
.ant-select-dropdown .ant-select-item,
.ant-select-dropdown .ant-select-item-option,
.ant-select-dropdown .ant-select-item-option-active,
.ant-select-dropdown .ant-select-item-option-selected {
  background-color: transparent !important;
}
.ant-select-dropdown .ant-select-item-option .ant-select-item-option-content {
  margin-left: 10px;
  font-weight: 400;
  font-size: 14px;
}
.ant-select-dropdown .ant-select-item-option::before {
  content: '';
  left: 0;
  top: 0;
  bottom: 0;
  padding: 5px;
  border: 1px solid #a8aeba;
  background-clip: content-box;
  border-radius: 999px;
  width: 22px;
}
.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled)::before {
  border: 1px solid #045afc;
  background-color: #045afc;
}
.ant-select-dropdown .ant-select-item-option-active {
  background-color: transparent;
}
.ant-select-dropdown .ant-select-item-option-active .ant-select-item-option-content {
  background-color: transparent;
  font-weight: 400;
  font-size: 14px;
}
.ant-input-affix-wrapper {
  border: none !important;
}
.search-input {
  padding: 0px;
  border: none;
}
.ant-input-affix-wrapper-focused {
  border-color: none !important;
}
.ant-input-affix-wrapper-focused:hover {
  border-color: none !important;
}
.ant-input-affix-wrapper:hover {
  border: none;
  border-color: none !important;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: none;
}
.ant-input-affix-wrapper:focus {
  border: none !important;
}
.container {
  max-width: 1540px;
  padding-left: 40px;
  padding-right: 40px;
}
@media screen and (max-width: 991px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 768px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.product-detail .ant-popover-content,
.product-detail .ant-popover-inner,
.product-detail .ant-popover-inner-content {
  border-radius: 20px;
}
.no-wrap {
  white-space: nowrap;
}
.modal-gacha-1 .customer-modal .ant-modal-content {
  max-width: 100%;
  box-shadow: 0px 0px 6px 0px #00000040;
}
.modal-gacha-1 .customer-modal .ant-modal-content .ant-modal-close {
  right: 15px;
  top: 15px;
}
.modal-gacha-1 .customer-modal .ant-modal-content .ant-modal-close .ant-modal-close-x {
  height: 24px;
  width: 24px;
}
.modal-gacha-1 .customer-modal .ant-modal-content .ant-modal-close .ant-modal-close-x .anticon-close {
  font-size: 24px !important;
}
@media screen and (max-width: 767px) {
  .modal-gacha-1 .customer-modal .ant-modal-content .ant-modal-close {
    right: 5px;
    top: 5px;
  }
  .modal-gacha-1 .customer-modal .ant-modal-content .ant-modal-close .ant-modal-close-x {
    height: 20px;
    width: 20px;
  }
  .modal-gacha-1 .customer-modal .ant-modal-content .ant-modal-close .ant-modal-close-x .anticon-close {
    font-size: 20px !important;
  }
}
.max-height.ant-spin {
  max-height: 100% !important;
}
